<template>
  <b-card no-body>
    <b-card-header class="pb-50 d-flex">
      <h5>
        Filtrer
      </h5>
      <template v-if="isMobile">
        <feather-icon
          class="chevron-left"
          :icon="isFilterOpenOnMobile ? 'ChevronDownIcon' : 'ChevronLeftIcon'"
          size="24"
          @click="isFilterOpenOnMobile = !isFilterOpenOnMobile"
        />
      </template>
    </b-card-header>
    <b-form-checkbox
      :checked="enableDateFilter"
      @input="(val) => $emit('update:enableDateFilter', val)"
    >
      Restreindre à une plage de dates
    </b-form-checkbox>
    <b-card-body v-if="!isMobile || isFilterOpenOnMobile">
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="8"
          class="mb-md-0 mb-2"
        >
          <div class="date-filter">
            <span class="text-nowrap mr-1 date-texte">Entre le</span>
            <b-form-datepicker
              start-weekday="1"
              :value="startDateFilter"
              class="w-100"
              reset-button
              today-button
              v-bind="labels || {}"
              :max="endDateFilter"
              placeholder="Saisir une date"
              @input="(val) => $emit('update:startDateFilter', val)"
            />
            <span class="text-nowrap mx-1 date-texte">et le</span>
            <b-form-datepicker
              start-weekday="1"
              :value="endDateFilter"
              class="w-100"
              reset-button
              today-button
              v-bind="labels || {}"
              :min="startDateFilter"
              placeholder="Saisir une date"
              @input="(val) => $emit('update:endDateFilter', val)"
            />
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Destination</label>
          <v-select
            :value="siteDestinationFilter"
            :options="siteDestinationOptions"
            :reduce="siteDestinationFilter => siteDestinationFilter.id"
            label="nom"
            class="w-100 select-cursor"
            @input="(val) => $emit('update:siteDestinationFilter', val)"
          ><div slot="no-options">
            Aucune option
          </div></v-select>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Date et Heure de livraison client</label>
          <b-form-datepicker
            start-weekday="1"
            :value="dateHeureLivraisonFilter"
            class="w-100"
            reset-button
            today-button
            v-bind="labels || {}"
            placeholder="Saisir une date"
            @input="(val) => $emit('update:dateHeureLivraisonFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Type véhicule</label>
          <v-select
            :value="modeleIdFilter"
            :options="modeleOptions"
            :reduce="modele => modele.id"
            label="nom"
            class="w-100 select-cursor"
            @input="(val) => $emit('update:modeleIdFilter', val)"
          ><div slot="no-options">
            Aucune option
          </div></v-select>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Immatriculation</label>
          <b-form-input
            :value="immatriculationFilter"
            class="w-100"
            @input="(val) => $emit('update:immatriculationFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Numéro de serie</label>
          <b-form-input
            :value="numeroDeSerieFilter"
            class="w-100"
            @input="(val) => $emit('update:numeroDeSerieFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Couleur</label>
          <b-form-input
            :value="couleurFilter"
            class="w-100"
            @input="(val) => $emit('update:couleurFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Cédé à</label>
          <b-form-input
            :value="proprietaireFilter"
            class="w-100"
            @input="(val) => $emit('update:proprietaireFilter', val)"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Type client</label>
          <v-select
            :value="typeClientFilter"
            :options="clientTypeOptions"
            :reduce="typeClient => typeClient.value"
            label="text"
            class="w-100 select-cursor"
            @input="(val) => $emit('update:typeClientFilter', val)"
          ><div slot="no-options">
            Aucune option
          </div></v-select>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Vendeur</label>
          <b-form-input
            :value="vendeurFilter"
            class="w-100"
            @input="(val) => $emit('update:vendeurFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Provenance</label>
          <v-select
            :value="provenanceFilter"
            :options="siteProvenanceOptions"
            :reduce="provenance => provenance.id"
            label="nom"
            class="w-100 select-cursor"
            @input="(val) => $emit('update:provenanceFilter', val)"
          ><div slot="no-options">
            Aucune option
          </div></v-select>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Numéro d'ordre</label>
          <b-form-input
            :value="numeroOrdreFilter"
            class="w-100"
            @input="(val) => $emit('update:numeroOrdreFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Date d'ARA</label>
          <b-form-datepicker
            start-weekday="1"
            :value="dateAraFilter"
            class="w-100"
            reset-button
            today-button
            v-bind="labels || {}"
            placeholder="Saisir une date"
            @input="(val) => $emit('update:dateAraFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Date de préparation</label>
          <b-form-datepicker
            start-weekday="1"
            :value="datePreparationFilter"
            class="w-100"
            reset-button
            today-button
            v-bind="labels || {}"
            placeholder="Saisir une date"
            @input="(val) => $emit('update:datePreparationFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Emplacement</label>
          <b-form-input
            :value="emplacementFilter"
            class="w-100"
            @input="(val) => $emit('update:emplacementFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BFormDatepicker, BFormCheckbox,
} from 'bootstrap-vue'
import datePickerLabels from '@/utils/datePickerLabels'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BFormDatepicker,
    vSelect,
    BFormCheckbox,
  },
  props: {
    enableDateFilter: {
      type: [Boolean],
      default: false,
    },
    startDateFilter: {
      type: [String, null],
      default: null,
    },
    endDateFilter: {
      type: [String, null],
      default: null,
    },
    siteDestinationFilter: {
      type: [Number, null],
      default: null,
    },
    siteDestinationOptions: {
      type: [Array],
      default: () => [],
    },
    dateHeureLivraisonFilter: {
      type: [String, null],
      default: null,
    },
    typeVehiculeFilter: {
      type: [String, null],
      default: null,
    },
    modeleOptions: {
      type: [Array],
      default: () => [],
    },
    modeleIdFilter: {
      type: [Number],
      default: null,
    },
    immatriculationFilter: {
      type: [String, null],
      default: null,
    },
    numeroDeSerieFilter: {
      type: [String, null],
      default: null,
    },
    couleurFilter: {
      type: [String, null],
      default: null,
    },
    proprietaireFilter: {
      type: [String],
      default: null,
    },
    typeClientFilter: {
      type: [String],
      default: null,
    },
    typeClientOptions: {
      type: [Array],
      default: null,
    },
    vendeurFilter: {
      type: [String],
      default: null,
    },
    provenanceFilter: {
      type: [Number],
      default: null,
    },
    siteProvenanceOptions: {
      type: [Array],
      default: () => [],
    },
    numeroOrdreFilter: {
      type: [String],
      default: null,
    },
    dateAraFilter: {
      type: [String],
      default: null,
    },
    datePreparationFilter: {
      type: [String],
      default: null,
    },
    emplacementFilter: {
      type: [String],
      default: null,
    },
  },
  data() {
    return {
      labels: datePickerLabels,
      clientTypeOptions: [
        { text: 'Société', value: 'societe' },
        { text: 'Particulier', value: 'particulier' },
        { text: 'Code 10', value: 'code10' },
        { text: 'Show room', value: 'showroom' },
      ],
      windowWidth: window.innerWidth,
      isFilterOpenOnMobile: false,
    }
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 768
    },
  },

  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.date-filter {
  display: flex;
  span {
    align-self: center;
  }

}

.dropdown-menu {
  z-index:50 !important;
}

@media screen and (max-width: 1024px) {
  .date-filter {
    flex-direction: column;
    span {
      align-self: start;
    }
  }
}
</style>
