<template>

  <div>

    <!-- Client Selector -->
    <client-selector
      v-if="$ability.can('read', 'Client')"
      :selected-client-id.sync="selectedClientId"
    />

    <!-- Filters -->
    <div class="filters">
      <app-collapse>
        <app-collapse-item :title="filtersTitle">
          <destockage-list-filters
            :start-date-filter.sync="startDateFilter"
            :end-date-filter.sync="endDateFilter"
            :site-destination-filter.sync="destinationIdFilter"
            :site-destination-options="siteDestinationOptions"
            :date-heure-livraison-filter.sync="dateHeureLivraisonFilter"
            :modele-id-filter.sync="modeleIdFilter"
            :modele-options.sync="modeleOptions"
            :immatriculation-filter.sync="immatriculationFilter"
            :numero-de-serie-filter.sync="numeroDeSerieFilter"
            :couleur-filter.sync="couleurFilter"
            :proprietaire-filter.sync="proprietaireFilter"
            :type-client-filter.sync="typeClientFilter"
            :type-client-options.sync="typeClientOptions"
            :vendeur-filter.sync="vendeurFilter"
            :provenance-filter.sync="provenanceFilter"
            :site-provenance-options.sync="siteProvenanceOptions"
            :numero-ordre-filter.sync="numeroOrdreFilter"
            :date-ara-filter.sync="dateAraFilter"
            :date-preparation-filter.sync="datePreparationFilter"
            :enable-date-filter.sync="enableDateFilter"
            :emplacement-filter.sync="emplacementFilter"
          />
          <b-row class="justify-content-end">
            <div class="col-2 clearBtnRow">
              <b-button
                class="pull-right"
                variant="primary"
                @click="onClearFiltersClicked()"
              >
                <span class="text-nowrap">{{ "Effacer tous les filtres" }}</span>
              </b-button>
            </div>
          </b-row>
        </app-collapse-item>
      </app-collapse>
    </div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 select-cursor"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Aucun véhicule ne correspond à la recherche"
        :sort-desc.sync="isSortDirDesc"
      />
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }}-{{ dataMeta.to }} {{ $t('on') }} {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
        <div class="mt-2">
          <b-button
            v-if="total"
            class="mr-2"
            variant="outline-primary"
            @click="exportToExcel()"
          >
            Exporter vers Excel
          </b-button>
        </div>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BPagination,
} from 'bootstrap-vue'
import router from '@/router'
import { watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import DestockageListFilters from '@/views/destockage/destockage-list/DestockageListFilters.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import useDestockageList from './useDestockageList'
import ClientSelector from '../../site/sites-list/ClientSelector.vue'
import { StorageManager, FILTER_CLIENT_KEY, DESTOCKAGE_LIST_FILTERS_KEY } from '../../../utils/StorageManager'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    vSelect,
    DestockageListFilters,

    ClientSelector,
    AppCollapse,
    AppCollapseItem,
  },
  mounted() {
    if (this.$ability.can('read', 'Client')) {
      if (router.currentRoute.params.id !== null && router.currentRoute.params.id !== undefined) {
        this.selectedClientId = router.currentRoute.params.id
      } else if (StorageManager.getFilter(FILTER_CLIENT_KEY) != null) {
        this.selectedClientId = StorageManager.getFilter(FILTER_CLIENT_KEY)
      }

      if (StorageManager.hasFilter(DESTOCKAGE_LIST_FILTERS_KEY)) {
        const destockageItems = StorageManager.getFilter(DESTOCKAGE_LIST_FILTERS_KEY)
        const destockageKeys = Object.keys(destockageItems)

        destockageKeys.forEach(key => {
          this[key] = destockageItems[key]
        })
      }
    }
  },
  setup() {
    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      selectedClientId,
      filtersTitle,
      startDateFilter,
      endDateFilter,
      destinationIdFilter,
      siteDestinationOptions,
      dateHeureLivraisonFilter,
      modeleIdFilter,
      modeleOptions,
      immatriculationFilter,
      numeroDeSerieFilter,
      couleurFilter,
      carburantFilter,
      codeRadioFilter,
      proprietaireFilter,
      typeClientFilter,
      typeClientOptions,
      vendeurFilter,
      provenanceFilter,
      dateAraFilter,
      siteProvenanceOptions,
      numeroOrdreFilter,
      datePreparationFilter,
      enableDateFilter,
      emplacementFilter,
      exportToExcel,
      clearAllFilters,
    } = useDestockageList()

    watch(selectedClientId, val => {
      StorageManager.setFilter(FILTER_CLIENT_KEY, val)
    })

    const onClearFiltersClicked = () => {
      clearAllFilters()
    }

    return {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // client selector
      selectedClientId,
      filtersTitle,
      startDateFilter,
      endDateFilter,
      destinationIdFilter,
      siteDestinationOptions,
      dateHeureLivraisonFilter,
      modeleIdFilter,
      modeleOptions,
      immatriculationFilter,
      numeroDeSerieFilter,
      couleurFilter,
      carburantFilter,
      codeRadioFilter,
      proprietaireFilter,
      typeClientFilter,
      typeClientOptions,
      vendeurFilter,
      provenanceFilter,
      dateAraFilter,
      siteProvenanceOptions,
      numeroOrdreFilter,
      datePreparationFilter,
      enableDateFilter,
      emplacementFilter,
      exportToExcel,
      onClearFiltersClicked,
      // event
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.filters {
  margin-bottom:2rem;
}

.clearBtnRow {
  padding-top:2rem;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
