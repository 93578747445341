import useRemoteList from '@/views/hooks/useRemoteList'
import { ref, watch, computed } from '@vue/composition-api'
import ability from '@/libs/acl/ability'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import i18n from '@/libs/i18n'
import { useToast } from 'vue-toastification/composition'
import moment from 'moment'
import FileManager from '@/utils/FileManager'
import { DESTOCKAGE_LIST_FILTERS_KEY, StorageManager } from '@/utils/StorageManager'

export default function useDestockageList() {
  const selectedClientId = ref(null)

  // Filters
  const enableDateFilter = ref(false)
  const today = moment()
  const tomorrow = today.clone().add(1, 'days')
  const startDateFilter = ref(today.format('YYYY-MM-DD'))
  const endDateFilter = ref(tomorrow.format('YYYY-MM-DD'))
  const destinationIdFilter = ref(null)
  const siteDestinationOptions = ref([])
  const dateHeureLivraisonFilter = ref(null)
  const modeleIdFilter = ref(null)
  const modeleOptions = ref([])
  const immatriculationFilter = ref(null)
  const numeroDeSerieFilter = ref(null)
  const couleurFilter = ref(null)
  const proprietaireFilter = ref(null)
  const typeClientFilter = ref(null)
  const typeClientOptions = ref([])
  const vendeurFilter = ref(null)
  const provenanceFilter = ref(null)
  const siteProvenanceOptions = ref([])
  const numeroOrdreFilter = ref(null)
  const dateAraFilter = ref(null)
  const datePreparationFilter = ref(null)
  const emplacementFilter = ref(null)

  // Use toast
  const toast = useToast()
  // use List
  const list = useRemoteList()

  const pageIndex = computed(() => list.currentPage.value - 1)

  const filtersTitle = computed(() => {
    const filters = [provenanceFilter?.value, modeleIdFilter?.value, numeroOrdreFilter?.value, numeroDeSerieFilter?.value, dateAraFilter?.value, enableDateFilter?.value, destinationIdFilter?.value, immatriculationFilter?.value, couleurFilter?.value, proprietaireFilter?.value, vendeurFilter?.value, typeClientFilter?.value, dateHeureLivraisonFilter?.value, datePreparationFilter?.value, emplacementFilter?.value]
    const activeFilters = filters.filter(v => v).length
    if (activeFilters === 0) {
      return 'Aucun filtre actif'
    }
    return activeFilters > 1 ? `${activeFilters} filtres actifs` : '1 filtre actif'
  })

  const storeDestockageFilters = () => {
    const storedFilters = {
      startDateFilter: startDateFilter.value,
      endDateFilter: endDateFilter.value,
      destinationIdFilter: destinationIdFilter.value,
      siteDestinationOptions: siteDestinationOptions.value?.filter(v => v.id === destinationIdFilter.value),
      provenanceFilter: provenanceFilter.value,
      siteProvenanceOptions: siteProvenanceOptions.value?.filter(v => v.id === provenanceFilter.value),
      modeleIdFilter: modeleIdFilter.value,
      modeleOptions: modeleOptions.value?.filter(v => v.id === modeleIdFilter.value),
      immatriculationFilter: immatriculationFilter.value,
      numeroDeSerieFilter: numeroDeSerieFilter.value,
      numeroOrdreFilter: numeroOrdreFilter.value,
      couleurFilter: couleurFilter.value,
      proprietaireFilter: proprietaireFilter.value,
      vendeurFilter: vendeurFilter.value,
      typeClientFilter: typeClientFilter.value,
      dateHeureLivraisonFilter: dateHeureLivraisonFilter.value,
      dateAraFilter: dateAraFilter.value,
      datePreparationFilter: datePreparationFilter.value,
      enableDateFilter: enableDateFilter.value,
      emplacementFilter: emplacementFilter.value,
    }

    StorageManager.setFilter(DESTOCKAGE_LIST_FILTERS_KEY, storedFilters)
  }

  const fetchData = () => {
    if (ability.can('read', 'Client') && !selectedClientId.value) {
      return []
    }

    let dateStart
    let dateEnd

    if (enableDateFilter.value) {
      dateStart = startDateFilter.value
      dateEnd = endDateFilter.value
    }

    const filters = {
      DateStart: dateStart,
      DateEnd: dateEnd,
      'FiltersToApply.SiteDestinationId': destinationIdFilter.value,
      'FiltersToApply.SiteProvenanceId': provenanceFilter.value,
      'FiltersToApply.ModeleId': modeleIdFilter.value,
      'FiltersToApply.Immatriculation': immatriculationFilter.value,
      'FiltersToApply.NumeroSerie': numeroDeSerieFilter.value,
      'FiltersToApply.NumeroOrdre': numeroOrdreFilter.value,
      'FiltersToApply.Couleur': couleurFilter.value,
      'FiltersToApply.CedeA': proprietaireFilter.value,
      'FiltersToApply.Vendeur': vendeurFilter.value,
      'FiltersToApply.ClientType': typeClientFilter.value,
      'FiltersToApply.DateLivraison': dateHeureLivraisonFilter.value,
      'FiltersToApply.DateARA': dateAraFilter.value,
      'FiltersToApply.DatePreparation': datePreparationFilter.value,
      'FiltersToApply.Emplacement': emplacementFilter.value,
    }

    let sortColumn = list.sortColumn.value ? list.sortColumn.value : 'SiteProvenanceId'
    if (sortColumn === 'SiteDestinationNom') {
      sortColumn = 'SiteDestinationId'
    }

    return store.dispatch('destockage/initFacturation', {
      clientId: selectedClientId.value,
      queryParams: {
        SortColumn: sortColumn,
        SortDirection: list.sortDirection.value,
        PageIndex: pageIndex.value,
        PageSize: list.perPage.value,
        ...filters,
      },
    })
      .then(response => {
        storeDestockageFilters()

        const { data, headers } = response
        list.total.value = parseInt(headers['x-total-count'], 10)
        modeleOptions.value = data.modeles
        siteProvenanceOptions.value = data.sitesProvenance
        siteDestinationOptions.value = data.sitesDestination

        if (siteProvenanceOptions.value.filter(v => v.id === provenanceFilter.value).length === 0) {
          provenanceFilter.value = null
        }
        if (siteDestinationOptions.value.filter(v => v.id === destinationIdFilter.value).length === 0) {
          destinationIdFilter.value = null
        }
        if (modeleOptions.value.filter(v => v.id === modeleIdFilter.value).length === 0) {
          modeleIdFilter.value = null
        }

        return data.vehicules
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('client.list.error.fetch'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return []
      })
  }

  const clearAllFilters = () => {
    destinationIdFilter.value = null
    provenanceFilter.value = null
    modeleIdFilter.value = null
    immatriculationFilter.value = null
    numeroDeSerieFilter.value = null
    numeroOrdreFilter.value = null
    couleurFilter.value = null
    proprietaireFilter.value = null
    vendeurFilter.value = null
    typeClientFilter.value = null
    dateHeureLivraisonFilter.value = null
    dateAraFilter.value = null
    enableDateFilter.value = null
    datePreparationFilter.value = null
    startDateFilter.value = today.format('YYYY-MM-DD')
    endDateFilter.value = tomorrow.format('YYYY-MM-DD')
    emplacementFilter.value = null
    list.refetchData()
  }

  const exportToExcel = () => {
    let dateStart
    let dateEnd

    if (enableDateFilter.value) {
      dateStart = startDateFilter.value
      dateEnd = endDateFilter.value
    }

    const filters = {
      DateStart: dateStart,
      DateEnd: dateEnd,
      'FiltersToApply.SiteDestinationId': destinationIdFilter.value,
      'FiltersToApply.SiteProvenanceId': provenanceFilter.value,
      'FiltersToApply.ModeleId': modeleIdFilter.value,
      'FiltersToApply.Immatriculation': immatriculationFilter.value,
      'FiltersToApply.NumeroSerie': numeroDeSerieFilter.value,
      'FiltersToApply.NumeroOrdre': numeroOrdreFilter.value,
      'FiltersToApply.Couleur': couleurFilter.value,
      'FiltersToApply.CedeA': proprietaireFilter.value,
      'FiltersToApply.Vendeur': vendeurFilter.value,
      'FiltersToApply.ClientType': typeClientFilter.value,
      'FiltersToApply.DateLivraison': dateHeureLivraisonFilter.value,
      'FiltersToApply.DateARA': dateAraFilter.value,
      'FiltersToApply.DatePreparation': datePreparationFilter.value,
      'FiltersToApply.Emplacement': emplacementFilter.value,
      SortColumn: list.sortColumn.value,
      SortDirection: list.sortDirection.value,
    }
    store.dispatch('destockage/exportFacturation', {
      clientId: selectedClientId.value,
      queryParams: {
        ...filters,
      },
    }).then(response => {
      if (response && response.data) { FileManager.forceFileDownload(response.data.filename, response.data.contentType, response.data.body) }
    })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Impossible d\'exporter les données vers Excel',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  watch([enableDateFilter, startDateFilter, endDateFilter, destinationIdFilter, dateHeureLivraisonFilter, modeleIdFilter, immatriculationFilter, numeroDeSerieFilter, couleurFilter, proprietaireFilter, typeClientFilter, vendeurFilter, provenanceFilter, numeroOrdreFilter, dateAraFilter, datePreparationFilter, emplacementFilter], () => {
    if (selectedClientId.value !== null || !ability.can('read', 'Client')) {
      list.refetchData()
    }
  })

  watch([selectedClientId], () => {
    if (selectedClientId.value !== null) {
      list.refetchData()
    }
  })

  // DATA
  const tableColumns = [
    {
      label: 'Destination', key: 'siteDestinationNom', sortable: true,
    },
    {
      label: 'Date et heure de livraison client', key: 'dateLivraison', sortable: true, formatter: date => (date !== null ? moment(date).format('DD/MM/YYYY') : ''),
    },
    { label: 'Modèle', key: 'modeleNom', sortable: true },
    {
      label: 'Immatriculation', key: 'immatriculation', sortable: true,
    },
    {
      label: 'Numéro de serie', key: 'numeroSerie', sortable: true, stickyColumn: true,
    },
    { label: 'Couleur', key: 'couleur', sortable: true },
    { label: 'Carburant', key: 'montantCarburant' },
    {
      label: 'Gravage', key: 'gravage', formatter: value => (value ? 'Oui' : 'Non'),
    },
    {
      label: 'Accessoire', key: 'accessoire', formatter: value => (value ? 'Oui' : 'Non'),
    },
    { label: 'Code Radio', key: 'codeAutoradio' },
    { label: 'Cédé à', key: 'cedeA', sortable: true },
    {
      label: 'OTS', key: 'ots', formatter: value => (value ? 'Oui' : 'Non'),
    },
    { label: 'Type Client', key: 'clientType', sortable: true },
    { label: 'Vendeur', key: 'vendeur', sortable: true },
    { label: 'Client Final', key: 'clientFinal', sortable: true },
    { label: 'Date de facturation', key: 'dateFacturation', formatter: date => (date !== null ? moment(date).format('DD/MM/YYYY') : '') },
    { label: 'Provenance', key: 'siteProvenanceNom', sortable: true },
    { label: 'Numéro d\'ordre', key: 'numeroOrdre', sortable: true },
    {
      label: 'Date d\'ARA', key: 'dateARA', sortable: true, formatter: date => (date !== null ? moment(date).format('DD/MM/YYYY') : ''),
    },
    {
      label: 'Date Préparation', key: 'datePreparation', sortable: true, formatter: date => (date !== null ? moment(date).format('DD/MM/YYYY') : ''),
    },
    {
      label: 'Jours de stock', key: 'nbJourStock', sortable: false,
    },
  ]

  return {
    tableColumns,
    fetchData,
    exportToExcel,

    // list
    ...list,

    selectedClientId,

    // filter
    filtersTitle,
    startDateFilter,
    endDateFilter,
    destinationIdFilter,
    siteDestinationOptions,
    dateHeureLivraisonFilter,
    modeleIdFilter,
    modeleOptions,
    immatriculationFilter,
    numeroDeSerieFilter,
    couleurFilter,
    proprietaireFilter,
    typeClientFilter,
    typeClientOptions,
    vendeurFilter,
    provenanceFilter,
    numeroOrdreFilter,
    dateAraFilter,
    siteProvenanceOptions,
    datePreparationFilter,
    clearAllFilters,
    enableDateFilter,
    emplacementFilter,
  }
}
